// import React from 'react'
import React, { useState, useEffect,useRef, useContext } from 'react'
import { InsightText, TreeTable, Button, Gauge, Tooltip, PageLoader, Tag } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';


const AccountWiseHealth = () => {
    const { 
        quickInfoData, 
        overallInfo, 
        insightText, 
        liveTreeAPIData, 
        Products 
    } = useContext<any>(HealthOverviewContext);
    const parentRef = useRef(null);



    const [showQuickInfo, setShowQuickInfo] = useState(false);
    const [liveTreeData, setLiveTreeData] = useState(liveTreeAPIData);
    const [averagePercentages, setAveragePercentages] = useState<object>({})
    const [overallPercentage, setOverallPercentage] = useState();
    const [accountName, setAccountName] = useState();
    const [parentHeight, setParentHeight] = useState(0);
    const [productsData, setProductData] = useState(Products);

    console.log('liveTreeData',liveTreeData)

    const nameBodyTemplate = (node: any) => {
        const type = node.data.Type
        const fontWeight = type === 'BUILDING' ? 'bold' : 'normal';
        return  <>
          <span className='gap-2'>
          {node.data?.liveStatus && (
              <span className='pr-2'><i className="pi pi-flag text-success" style={{ fontSize: '0.9rem' }}></i></span>
            )}
            <span style={{ fontWeight: fontWeight }}>{node.data.Name}</span>
          </span>
            
          </>
    };

    // const productDataTemplate = (node: any, metric: string) => {
    //     console.log('node',node)
    //     const { [metric]: { total = 0, faulty = 0 } = {} } = node.data || {};
    //     console.log('metric',metric)
    
    //     if (node.data?.Type === 'FLOOR') {
    //         return (
    //             <div className='flex justify-start items-center gap-2'>
    //                 <p className='cursor-pointer'>{total ? total : 0}</p>
    //                 {faulty ? (
    //                     <Tag value={faulty} severity={'danger'} />
    //                 ) : (
    //                     <span></span>
    //                 )}
    //             </div>
    //         );
    //     } else if (node.data?.Type === 'BUILDING') {
    //         return (
    //             <div className='flex justify-start items-center gap-2'>
    //                 <p className=''>{total ? total : ''}</p>
    //             </div>
    //         );
    //     }
    
    //     return null;
    // };
    

    const productDataTemplate = (node: any, metric: string) => {
        
        const { [metric]: { total = 0, faulty = 0 } = {} } = node.data || {};
    
        if (node.data?.Type === 'FLOOR') {
            return (
                <div className='flex justify-start items-center gap-2'>
                    <p className='cursor-pointer'>{total ? total : 0}</p>
                    {faulty ? (
                        <Tag value={faulty} severity={'danger'} />
                    ) : (
                        <span></span>
                    )}
                </div>
            );
        } else if (node.data?.Type === 'BUILDING') {
            return (
                <div className='flex justify-start items-center gap-2'>
                    <p className=''>{total ? total : ''}</p>
                </div>
            );
        }
        return null;
    };

    
    const extraTableHeaderElement = [
        <div key="clear-filter" className="flex justify-center items-center gap-2">
                <Button
                    size="small"
                    icon="pi pi-sparkles"
                    onClick={() => {
                        console.log('sparkles clciked');
                        setShowQuickInfo(!showQuickInfo);
                    }}
                />
        </div>
    ]

    const rowClassName = (liveTreeData) => {
        return { 'p-highlight': (liveTreeData.data && liveTreeData.data.Type !== 'FLOOR') };
    }

    // const columns = [
    //     { 
    //         field: 'Name', 
    //         header: 'Name', 
    //         className:'text-xs whitespace-nowrap', 
    //         fixShowCol: true, 
    //         frozen:true, 
    //         expander: true, 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => nameBodyTemplate(liveTreeData) 
    //     },
    //     { 
    //         field: 'Type', 
    //         header: 'Type',
    //         className:'text-xs whitespace-nowrap', 
    //         fixShowCol: true, 
    //         style: { 'width':'auto',height:'50px'},
            
    //     },
    //     { 
    //         field: 'Id', 
    //         header: 'Id',  
    //         className:'text-xs whitespace-nowrap', 
    //         fixShowCol: true, 
    //         style: { 'width':'auto',height:'50px'}, 
    //     },
        
    //     { 
    //         field: 'Overall', 
    //         header: 'Overall', 
    //         fixShowCol: true,
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         // colBody: (liveTreeData: any) => productDataTemplate(liveTreeData, 'Overall')
    //     },
    //     { 
    //         field: 'FLO', 
    //         header: 'FLO',
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'} ,
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'FLO')
    //     },
    //     { 
    //         field: 'BAT-FLO', 
    //         header: 'BAT-FLO',
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'BAT-FLO')
    //     },
    //     { 
    //         field: 'BAT-TIM',
    //         header: 'BAT-TIM', 
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'BAT-TIM')
    //     },
    //     { 
    //         field: 'TIM+', 
    //         header: 'TIM+', 
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'TIM+') 
    //     },
    //     { 
    //         field: 'TIM', 
    //         header: 'TIM', 
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'TIM') 
    //     },
    //     { 
    //         field: 'ONE', 
    //         header: 'ONE', 
    //         sortable:true, 
    //         className:'text-xs whitespace-nowrap', 
    //         style: { 'width':'auto',height:'50px'},
    //         colBody: (liveTreeData: any) => productDataTemplate(liveTreeData,'ONE') 
    //     }
    // ];

    function generateColumns(products) {
        // Default columns
        const baseColumns = [
          { 
            field: 'Name', 
            header: 'Name', 
            className: 'text-xs whitespace-nowrap lg:w-1/4 md:w-1/3 sm:w-1/2 h-12' ,
            fixShowCol: true, 
            frozen: true, 
            expander: true, 
            // style: { 'width': 'auto', 'height': '50px' },
            colBody: (liveTreeData) => nameBodyTemplate(liveTreeData),
          },
          { 
            field: 'Type', 
            header: 'Type', 
            className: 'text-xs whitespace-nowrap lg:w-1/4 md:w-1/3 sm:w-1/2 h-12' ,
            fixShowCol: true, 
            // style: { 'width': 'auto', 'height': '50px' },
          },
          { 
            field: 'Id', 
            header: 'Id', 
            className: 'text-xs whitespace-nowrap lg:w-1/4 md:w-1/3 sm:w-1/2 h-12' ,
            fixShowCol: true, 
            // style: { 'width': 'auto', 'height': '50px' },
          },
          { 
            field: 'Overall', 
            header: 'Overall',
            fixShowCol: true, 
            sortable: true, 
            className: 'text-xs whitespace-nowrap lg:w-1/4 md:w-1/3 sm:w-1/2 h-12' ,
            // style: { 'width': 'auto', 'height': '50px' },
          }
        ];
        
        const productColumns = products.map(product => ({
            field: product, 
            header: product, 
            sortable: true, 
            className: 'text-xs whitespace-nowrap lg:w-1/4 md:w-1/3 sm:w-1/2 h-12' ,
            // style: { 'width': 'auto', 'height': '50px' },
            colBody: (liveTreeData) => productDataTemplate(liveTreeData, product),
          }));
        
          // Return a combination of base columns and dynamic product columns
          return [...baseColumns, ...productColumns];
        }

    const columns = generateColumns(productsData);



    useEffect(() => {
        setAveragePercentages(quickInfoData)
        setOverallPercentage(overallInfo)
    }, [quickInfoData, overallInfo])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('spaceio-user'));
        setAccountName(userInfo?.account?.name || 'Unknown Account');
    }, []);

    //scroll height
    useEffect(() => {
        if (parentRef.current) {
          // Get the height of the parent element
          const height = parentRef.current.getBoundingClientRect().height;
          setParentHeight(height);
        }
    
        // Optional: Listen to window resize events in case the height changes
        const handleResize = () => {
          if (parentRef.current) {
            const height = parentRef.current.getBoundingClientRect().height;
            setParentHeight(height);
          }
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (liveTreeAPIData) {
            setLiveTreeData(liveTreeAPIData);
        }
    }, [liveTreeAPIData]);

    const getTags = (treeData: any[]): { risk: string; body: JSX.Element }[] => {
        const uniqueRisks = new Set<string>();
    
        // Recursive function to traverse the tree
        const traverseTree = (nodes: any[]) => {
            nodes.forEach((node) => {
                const risk = node.data?.risk;
                if (risk) {
                    uniqueRisks.add(risk);
                }
                if (node.children && node.children.length > 0) {
                    traverseTree(node.children);
                }
            });
        };
    
        // Start traversal
        traverseTree(treeData);
    
        // Map unique risks to visual representation
        const tags = Array.from(uniqueRisks).map((risk) => {
            let colour = 'text-green-500';
            if (risk === 'Low') {
                colour = 'text-green-500';
            } else if (risk === 'Medium') {
                colour = 'text-amber-500';
            } else {
                colour = 'text-error';
            }
    
            return {
                risk,
                body: (
                    <div className="flex flex-row justify-center items-center gap-2">
                        <span>
                            <i className={`${colour} text-xs pi pi-circle-fill`}></i>
                        </span>
                        <p>{risk}</p>
                    </div>
                ),
            };
        });
    
        return tags;
    };
   
    const status = [
        {
            body: <div className=' flex flex-row justify-center items-center gap-2 '>
                <span>
                    <i className="text-[10px]"></i>
                </span>
                <p>Live</p>
            </div>,
            tag: 'Live'
        },
        {
            body: <div className=' flex flex-row justify-center items-center gap-2 '>
                <span>
                    <i className="text-[10px]"></i>
                </span>
                <p>UnLive</p>
            </div>,
            tag:'Archive'
        } 
    ];

    const multiSelectFilter = [

        {
            key: 'risk',
            lableKey: 'body',
            border: false,
            placeholder: 'Overall Risk',
            searchFilter: true,
            option: getTags(liveTreeData),
        },
        // {
        //     key: 'liveStatus',
        //     lableKey: 'body',
        //     border: false,
        //     placeholder: 'Status',
        //     searchFilter: false,
        //     option: status,
        // }

    ]

    const dropDownFilter = [
        {
            key: 'status',
            lableKey: 'lable',
            placeholder: 'Status',
            searchFilter: false,
            defaultOptionValue: [true,false],
            option: [
                { status: ['Live'], lable: 'Live' },
                { Status: ['Archive'], lable: 'UnLive' },
            ],
        }
    ]

    return (
        <div ref={parentRef} className="flex w-full h-screen">
            <div className="flex flex-col w-full"  >
                <div className=" flex w-full h-full divide-x divide-dividerLines bg-bgColor">
                    <div className="w-full flex flex-col justify-between ">
                    <div className={`${showQuickInfo ? 'w-28' : 'w-full'}`}>
                    <div
                        style={{
                        backgroundColor: 'rgba(243, 252, 248, 1)',
                        boxShadow: '0px 4px 4px 0px #0000000F',
                        }}
                        className={`flex bg-accent1Rollover h-16 p-2 justify-between overflow-x-auto 
                        [&::-webkit-scrollbar]:hidden 
                        [-ms-overflow-style]:none 
                        [scrollbar-width]:none`}
                    >
                        {insightText.map(item => (
                        <InsightText
                            key={item.id}
                            primaryText={
                            <div>
                                <p
                                className="text-xs"
                                style={{ color: '#1E253E', fontSize: '12px' }}
                                >
                                {`${item.id} - ${item.name} `}
                                </p>
                            </div>
                            }
                            secondaryText={
                            <div className="flex flex-col text-xs">
                                <p
                                className="text-xs"
                                style={{ color: '#6D7492', fontSize: '12px' }}
                                >
                                {`${item.anchorAddress.split(',')[0]?.trim()} - ${
                                    item.anchorAddress.split(',')[1]?.trim()
                                }`}
                                </p>
                            </div>
                            }
                            contentInput={
                            <p
                                className="text-xs"
                                style={{
                                color: '#FF5C5C',
                                fontSize: '12px',
                                fontWeight: '700',
                                }}
                            >
                                {item.faultyPercentage}%
                            </p>
                            }
                        />
                        ))}
                    </div>
                    {liveTreeData ? (
                        <div
                        className="w-full h-[calc(100%-4rem)] overflow-hidden"
                        >
                        <TreeTable
                            data={liveTreeAPIData}
                            columns={columns}
                            globalFilterFields={['Name', 'Overall']}
                            frozenWidth="300px"
                            scrollHeight="calc(100vh - 14rem)"
                            expandAll={false}
                            rowClassName={rowClassName}
                            multiSelectFilter={multiSelectFilter}
                            // dropDownFilter={dropDownFilter}
                            extraTableHeaderElement={extraTableHeaderElement}
                        />
                        </div>
                    ) : (
                        <PageLoader loading={true} />
                    )}
                    </div>

                    </div>

                    {
                        showQuickInfo && (
                            <div className='w-1/5 bg-customGray flex flex-col items-start' style={{ width: '23%', gap: '17px', borderRight: '2px' }}>
                                <p className='font-serif font-medium text-base pl-3 ' style={{ color: '#1E253E',paddingTop:'14px',paddingLeft:'13px'}}>Quick info</p>
                               
                                <div className=" text-xs font-medium items-center flex flex-col w-full" >
                                    <div className='pb-2' style={{ width: '10rem' }}>
                                        <Gauge
                                            percentage={10}
                                            lableOn={true}
                                            left={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>Low Risk</p>}
                                            right={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>High Risk</p>}
                                        />
                                    </div>
                                    <div className=" text-sm items-center flex flex-col" style={{color:'#555555',fontWeight:'700'}}>
                                        <span
                                            className="font-semibold font-sans"
                                            style={{ color:'#555555', textAlign: "center" }}>
                                            {accountName} is at
                                        </span>
                                        <span className="font-semibold font-sans text-[#555555]  " style={{color:'#555555'}}>
                                            {Math.floor(parseFloat(overallPercentage))}% {overallPercentage ? (overallPercentage > 30 ? 'Low Risk' : 'High Risk') : 'No Information Available'}
                                        </span>
                                    </div>
                                </div>
                                <hr className="w-full border-t border-gray-300" />
                                {Object.keys(averagePercentages).map((key) => (
                                    <>
                                        {averagePercentages[key]?.total > 0 && (
                                            <div className="flex gap-4 items-center"style={{ paddingTop: '10px', paddingLeft: '23px' }} > 
                                           
                                                <div style={{ width: '70px' }} >
                                                    <Gauge
                                                        percentage={parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 || parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))} // Set percentage based on the average percentage for the key
                                                        lableOn={false} // Assuming this is a prop for the Gauge component to toggle label visibility
                                                    />
                                                </div>
                                                <p className="font-sans text-sm font-bold " style={{color: '#1E253E'}}>{averagePercentages[key]?.total > 0 ? Math.floor((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100) : 0}% {key} </p>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )

}

export default AccountWiseHealth;