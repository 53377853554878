import { useState, useEffect, useContext, ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react'
import { InsightText, Table, Tag, Gauge, Button, Switch } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';
import { useNavigate } from 'react-router-dom';
import { SpaceIOService } from 'auth-utils'

interface ColumnMeta {
    field: string;
    header: string;
    fixShowCol?: boolean;
    exportable?: boolean;
    sortable: boolean;
    frozen?: boolean;
    sortValue: string | null | ((rowData: any) => any);
    colBody: string | number | boolean | ReactElement<any, string |
        JSXElementConstructor<any>> | Iterable<ReactNode> |
    ReactPortal | ((data: any, options: any) => React.ReactNode) | null | undefined;
    alignFrozen?: 'right' | 'left' | undefined;
}

const getFileName = () => {
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toISOString().replace('T', '_').slice(0, 19);
    const downloadFileName = `AllAccount_Health_${formattedDateTime}`;
    return downloadFileName;
}

const HealthOverview = () => {
    const navigate = useNavigate();

    const { accountsInfo } = useContext<any>(HealthOverviewContext)
    const { insightTextData } = useContext<any>(HealthOverviewContext)
    const { quickInfoData } = useContext<any>(HealthOverviewContext)

    const [isRiskView, setIsRiskView] = useState(true);
    const [showQuickInfo, setShowQuickInfo] = useState(false);
    const [averagePercentages, setAveragePercentages] = useState<object>({})

    const [downloadFileName, setdownloadFilename] = useState<string>(getFileName())

    useEffect(() => {
        setAveragePercentages(quickInfoData)
    }, [averagePercentages])

    const tagBodyTemplate = (prop: any) => {

        if (prop.value != null) {
            let severity = null;
            if (prop.value > 0 && prop.value <= 5) {
                severity = 'success';
            } else if (prop.value >= 6 && prop.value <= 15) {
                severity = 'warning';
            } else {
                severity = 'danger'
            }
            return <Tag value={prop.value} severity={severity} />
        }
    };

    const numberBodyTemplate = (value: any) => {
        let colour = 'black';

        if (!isRiskView) {
            return (
                <span className={`text-${colour}`}>
                    {value}
                </span>
            )
        } else {
            if (value > 0 && value <= 5) {
                colour = 'black';
            } else if (value >= 6 && value <= 15) {
                colour = 'amber-500';
            } else {
                colour = 'error'
            }

            return (
                <span className={`text-${colour}`}>
                    {value}
                </span>
            )
        }
    };

    const stringBodyTemplate = (value: any) => {
        return (
            <span>
                {value}
            </span>
        )
    };

    const imageBodyTemplate = (prop: any) => {
        return <img
            src={prop.src}
            alt={prop.alt}
            className=" w-auto h-6"
        />;
    };

    const overallRiskInfo = (value: number) => {
        let overallRiskInfo = 'Low';
        if (value > 0 && value <= 5) {
            overallRiskInfo = 'Low';
        } else if (value >= 6 && value <= 15) {
            overallRiskInfo = 'Medium';
        } else {
            overallRiskInfo = 'High';
        }
        return overallRiskInfo
    }

    const getTags = (data: Array<{ risk?: string }>) => {
        // Extract unique risk values from the data
        const uniqueRisks = [...new Set(data.map((d) => d?.risk))];

        // Map the risks to options in optionsforOverallRisk
        const Tags = uniqueRisks
            .map((risk) => {
                let colour = 'text-green-500'
                if (risk == 'Low') {
                    colour = 'text-green-500'
                } else if (risk == 'Medium') {
                    colour = 'text-amber-500'
                } else {
                    colour = 'text-error'
                }

                const object = {
                    body: <div className=' flex flex-row justify-center items-center gap-2 '>
                        <span>
                            <i className={` ${colour} text-xs pi pi-circle-fill`}></i>
                        </span>
                        <p>{risk}</p>
                    </div>,
                    risk,
                }
                return object;
            })
        return Tags;
    };

    const columns: ColumnMeta[] = [
        {
            field: 'logo',
            header: 'Logo',
            fixShowCol: true,
            sortable: false,
            exportable: false,
            frozen: true,
            sortValue: (rowData: any) => (rowData.logo),
            colBody: (rowData: any) => imageBodyTemplate({ src: rowData.logo, alt: rowData['Account Name'] })
        },
        // {
        //     field: 'Account Id',
        //     header: 'Account Info',
        //     sortable: true,
        //     sortValue: (rowData: any) => (rowData['Account Id']),
        //     colBody: (rowData: any) => complexTextBodyTemplate({
        //         name: rowData['Account Name'],
        //         value: rowData['Account Id']
        //     })
        // },
        {
            field: 'Accounts',
            header: 'Accounts',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData['Accounts']),
            colBody: (rowData: any) => stringBodyTemplate(rowData['Accounts'])
        },
        {
            field: 'Id',
            header: 'Id',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData['Id']),
            colBody: (rowData: any) => (<p>{rowData['Id']}</p>)
        },
        {
            field: 'Overall',
            header: 'Overall %',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData.Overall),
            colBody: (rowData: any) => tagBodyTemplate({ value: rowData.Overall }),
        },
        {
            field: 'ONE',
            header: 'ONE %',
            sortable: true,
            sortValue: (rowData: any) => (rowData.ONE),
            colBody: (rowData: any) => numberBodyTemplate(rowData.ONE)
        },
        {
            field: 'TIM',
            header: 'TIM %',
            sortable: true,
            sortValue: (rowData: any) => (rowData.TIM),
            colBody: (rowData: any) => numberBodyTemplate(rowData.TIM)
        },
        {
            field: 'FLO',
            header: 'FLO %',
            sortable: true,
            sortValue: (rowData: any) => (rowData['FLO']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['FLO'])
        },
        {
            field: 'BAT-TIM',
            header: 'BAT-TIM %',
            sortable: true,
            sortValue: (rowData: any) => (rowData.TIM),
            colBody: (rowData: any) => numberBodyTemplate(rowData['BAT-TIM'])
        },
        {
            field: 'BAT-TIM+',
            header: 'BAT-TIM+ %',
            sortable: true,
            sortValue: (rowData: any) => (rowData['BAT-TIM+']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['BAT-TIM+'])
        },
        {
            field: 'PAL',
            header: 'PAL %',
            sortable: true,
            sortValue: (rowData: any) => (rowData['PAL']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['PAL'])
        },
        {
            field: 'PAM',
            header: 'PAM %',
            sortable: true,
            sortValue: (rowData: any) => (rowData['PAM']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['PAM'])
        },
        // {
        //     field: 'risk',
        //     header: 'Risk',
        //     sortable: true,
        //     frozen: true,
        //     alignFrozen: 'right',
        //     sortValue: (rowData: any) => (rowData.risk),
        //     colBody: (rowData: any) => tagBodyTemplate({
        //         severity: 'info',
        //         value: rowData.risk
        //     }),
        // },
        // {
        //     field: 'actions',
        //     header: 'Action',
        //     sortable: false,
        //     frozen: true,
        //     alignFrozen: 'right',
        //     sortValue: null,
        //     colBody: (rowData: any) => actionBodyTemplate(rowData),
        // },
    ];

    const formatDeviceCount = (deviceCount: number) => {
        if (deviceCount > 999) {
            return `${(deviceCount / 1000).toFixed(1)}K`;
        }
        return deviceCount;
    }

    const accountInfoData = accountsInfo.map((account) => {
        const OverallNumValue = account.accountLevelfaultyDevices?.Overall === null ? null : 100 - account.accountLevelTotalDevices?.Overall;
        const Overall_value = formatDeviceCount(OverallNumValue);
        const oneValue = isRiskView ? account.accountLevelfaultyDevices.ONE : formatDeviceCount(account.accountLevelTotalDevices.ONE);
        const timValue = isRiskView ? account.accountLevelfaultyDevices.TIM : formatDeviceCount(account.accountLevelTotalDevices.TIM);
        const timPlusValue = isRiskView ? account.accountLevelfaultyDevices['TIM+'] : formatDeviceCount(account.accountLevelTotalDevices['TIM+']);
        const batTimValue = isRiskView ? account.accountLevelfaultyDevices['BAT-TIM'] : formatDeviceCount(account.accountLevelTotalDevices['BAT-TIM']);
        const batTimPlusValue = isRiskView ? account.accountLevelfaultyDevices['BAT-TIM+'] : formatDeviceCount(account.accountLevelTotalDevices['BAT-TIM+']);
        const batFloValue = isRiskView ? account.accountLevelfaultyDevices['BAT-FLO'] : formatDeviceCount(account.accountLevelTotalDevices['BAT-FLO']);
        const palValue = isRiskView ? account.accountLevelfaultyDevices.PAL : formatDeviceCount(account.accountLevelTotalDevices.PAL);
        const pamValue = isRiskView ? account.accountLevelfaultyDevices.PAM : formatDeviceCount(account.accountLevelTotalDevices.PAM);
        const floValue = isRiskView ? account.accountLevelfaultyDevices.FLO : formatDeviceCount(account.accountLevelTotalDevices.FLO);

        const objectData = {
            logo: account.logoUrl,
            Id: account.id,
            Accounts: account.name,
            liveStatus: account.showInMenu,
            Overall: Overall_value,
            risk: overallRiskInfo(OverallNumValue),
            ONE: oneValue,
            TIM: timValue,
            FLO: floValue,
            'TIM+': timPlusValue,
            'BAT-TIM': batTimValue,
            'BAT-TIM+': batTimPlusValue,
            'BAT-FLO': batFloValue,
            PAL: palValue,
            PAM: pamValue,
        }

        return objectData
    });

    const handleClick = async (accountId: number) => {
        await SpaceIOService.switchAccount(accountId);
        navigate('/account-health-overview')
    }

    const handleSelectedCell = async (cell) => {
        if (cell.cellIndex < 3) {
            handleClick(cell.rowData['Id'])
        } else if (cell.cellIndex > 3) {
            await SpaceIOService.switchAccount(cell.rowData['Id']);
            navigate('/device-health', {
                state: {
                    ProductType: cell.field
                }
            })
        }
    };

    const ondownloadEventFunction = () => {
        // console.log('dowload is started ...')
        const fileName = getFileName();
        setdownloadFilename(fileName);
    }

    return (
        <div className=' flex flex-auto overflow-hidden'>
            {/* Main content */}
            <div className={`${showQuickInfo ? 'md:w-4/5 lg:w-4/5 w-full' : 'w-full'} h-full flex flex-col `}>
                {/* InsightText */}
                <div className='flex p-2 justify-between min-h-16 flex-initial bg-accent1Rollover 
                        drop-shadow-[0_10px_10px_rgb(0,0,0,0.1)]'>
                    {insightTextData.map(item => (
                        <InsightText
                            key={item.id}
                            primaryText={
                                <div>
                                    <p className='text-xs text-primaryText'>{`${item.AccountName} - ${item.anchorAddress.split(',')[0]?.trim()}`}</p>
                                </div>
                            }
                            secondaryText={
                                <div className='flex flex-col text-xs'>
                                    <p className='text-xs text-secondaryText '>{`${item.anchorAddress.split(',')[1]?.trim()} - ${item.name}`}</p>
                                </div>
                            }
                            contentInput={
                                <p className="text-xs text-error">
                                    {item.TotalFaulty > 300 ? '70' : item.TotalFaulty > 160 ? '30' : '20'}%
                                </p>
                            }
                        />
                    ))}
                </div>
                {/* End of InsightText */}

                {/* table */}
                <div className='flex-auto overflow-hidden mt-6'>
                    <Table
                        data={accountInfoData}
                        columns={columns}
                        minWidth={'80rem'}
                        multiSelectFilter={[
                            {
                                key: 'Accounts',
                                lableKey: 'Accounts',
                                transparent: true,
                                placeholder: 'Account Name',
                                searchFilter: true,
                                option: accountInfoData,
                                onFilterChange: () => {}
                            },
                            {
                                key: 'risk',
                                lableKey: 'body',
                                transparent: true,
                                placeholder: 'Overall Risk',
                                searchFilter: true,
                                option: getTags(accountInfoData),
                                onFilterChange: () => {}
                            }
                        ]}
                        ondownloadEvent={ondownloadEventFunction}
                        downloadFileText={downloadFileName}
                        dropDownFilter={[{
                            key: 'liveStatus',
                            lableKey: 'lable',
                            placeholder: 'Live Status',
                            searchFilter: false,
                            defaultOptionValue: [true],
                            option: [
                                { liveStatus: [true], lable: 'Active' },
                                { liveStatus: [false], lable: 'Archive' },
                                { liveStatus: [true, false], lable: 'All' },
                            ],
                            onFilterChange: () => {}
                        }]}
                        setSelectedCell={handleSelectedCell}
                        extraTableHeaderElement={[
                            <div key="in-risk-view" className="flex justify-center divide-x-2 divide-dividerLines items-center gap-2">
                                <div className='grid grid-flow-col gap-2 justify-center items-center'>
                                    <p className="text-xs font-sans">In-Risk View</p>
                                    <Switch
                                        checkedStatus={isRiskView}
                                        onChange={(isChecked) => {
                                            setIsRiskView(isChecked);
                                        }}
                                    />
                                </div>
                                <div key="clear-filter" className="pl-2 flex justify-center items-center gap-2">
                                    <Button
                                        size="small"
                                        icon="pi pi-sparkles"
                                        onClick={() => {
                                            setShowQuickInfo(!showQuickInfo);
                                        }}
                                    />
                                </div>
                            </div>
                        ]}
                        globalFilterFields={['Id', 'Accounts']}
                        downloadOption={true}
                        pagination={true}
                        numberOfRows={20}
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </div>
                {/* End table */}
            </div>
            {/* quick info */}
            {
                showQuickInfo &&
                <div className='md:w-1/5 lg:w-1/5 w-0 p-5 bg-webBg border-l-2'>
                    <div className="flex flex-row justify-between items-center mb-4">
                        <p className='font-bold'>Quick Info</p>
                        <i className="pi pi-times text-xs cursor-pointer
                                    text-placeHolder opacity-65 hover:text-placeHolder hover:opacity-100"
                            onClick={() => {
                                setShowQuickInfo(!showQuickInfo)
                            }}
                        >
                        </i>
                    </div>
                    <div className=' flex flex-col gap-6 font-sans text-xs'>
                        {
                            Object.keys(averagePercentages).map(key => (
                                <div className='flex gap-6 items-center' key={key}>
                                    <div className=' w-20'>
                                        <Gauge
                                            percentage={
                                                parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 ||
                                                    parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))
                                            } // Set percentage based on the average percentage for the key
                                            lableOn={false}
                                        />
                                    </div>
                                    <div>
                                        <p className=" text-primaryText font-bold">{averagePercentages[key]?.total > 0 ? parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) : 0}%  {key}  </p>
                                        <p className={'text-gray-400'} >{averagePercentages[key]?.healthy}/{averagePercentages[key]?.total}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
            {/* End of quick info */}
        </div>
    )
}

export default HealthOverview;