import React, { FC } from "react";
import { SVGProps } from "../../../types/Misc";
const DeviceIcon: FC<SVGProps> = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1.08936" width="19" height="19" rx="3.22093" fill="#748ADF" fill-opacity="0.2" stroke="#5C75D6" />
        <path d="M12.5745 5.25513C12.5745 6.34867 11.6636 7.25513 10.515 7.25513C9.36637 7.25513 8.45544 6.34867 8.45544 5.25513C8.45544 4.16158 9.36637 3.25513 10.515 3.25513C11.6636 3.25513 12.5745 4.16158 12.5745 5.25513Z" stroke="#5C75D6" />
        <path d="M6.45239 19.4991H14.1905V19.2666C14.1905 17.2116 12.5246 15.5457 10.4696 15.5457H10.1733C8.11831 15.5457 6.45239 17.2116 6.45239 19.2666V19.3829" stroke="#5C75D6" />
        <path d="M8.899 11.5679L10.3879 13.6005C10.4012 13.6187 10.4187 13.6334 10.439 13.6436C10.4593 13.6538 10.4818 13.6592 10.5046 13.6592H10.5049C10.5279 13.6591 10.5504 13.6537 10.5708 13.6435C10.5911 13.6332 10.6087 13.6183 10.622 13.6001L12.1012 11.5675C12.1165 11.5465 12.1256 11.5218 12.1274 11.496C12.1293 11.4703 12.1238 11.4446 12.1117 11.4217C12.0996 11.3988 12.0813 11.3796 12.0588 11.3662C12.0362 11.3528 12.0103 11.3457 11.9839 11.3458H11.2877L11.2878 9.52013C11.2878 9.50169 11.2841 9.48343 11.2769 9.46639C11.2697 9.44936 11.2591 9.43388 11.2457 9.42084C11.2324 9.4078 11.2165 9.39746 11.1991 9.39042C11.1816 9.38337 11.1629 9.37975 11.144 9.37976L9.85598 9.37982C9.83709 9.37981 9.81839 9.38344 9.80095 9.39049C9.7835 9.39755 9.76765 9.40789 9.7543 9.42093C9.74095 9.43397 9.73036 9.44946 9.72313 9.4665C9.71591 9.48354 9.7122 9.5018 9.7122 9.52024V11.3456H9.01586C8.96215 11.3456 8.91283 11.3752 8.88803 11.4219C8.87591 11.4449 8.87052 11.4707 8.87246 11.4964C8.87439 11.5222 8.88358 11.5469 8.899 11.5679Z" fill="#FF5C5C" />
    </svg>
);
export default DeviceIcon;
