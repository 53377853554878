import { useState, useEffect } from 'react';
import HealthOverviewContext from './HealthOverviewContext';
import { SpaceIOService } from 'auth-utils'
import { PageLoader } from "freespace-react-components";


const HealthOverviewProvider = ({ children }) => {

    const [accountsInfo, setAccountsInfo] = useState(null);
    const [quickInfoData, setQuickInfoData] = useState({});
    const [faultyFloorsDetails, setFaultyFloorsDetails] = useState(null)

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let productTypes = ['ONE', 'TIM', 'FLO', 'TIM+', 'BAT-TIM', 'BAT-FLO', 'BAT-TIM+', 'PAM', 'PAL']


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchListofAccountWithLocation(productTypes);
                setQuickInfoData(data.productLevelDeviceHealth);
                setFaultyFloorsDetails(data.faultyFloors)
                setAccountsInfo(data.responseData);
                setLoading(false);
            } catch (error) {
                throw (error)
            }
        };
        fetchData();
    }, []);


    const fetchHealthDataFloorWise = async (arrayOfFloorAssetId: string[]) => {
        if (arrayOfFloorAssetId.length > 0) {
            let data = JSON.stringify(arrayOfFloorAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_HEALTHY_DEVICES}/current`,
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_HEALTHY_DEVICES}/current`
                    , config, true, true
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };

    const fetchTOtalDeviceDataFloorWise = async (arrayOfFloorAssetId: string[]) => {
        if (arrayOfFloorAssetId.length > 0) {
            let data = JSON.stringify(arrayOfFloorAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_DEVICES}/current`,
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_DEVICES}/current`
                    , config, true, true
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };

    const fetchliveAccount = async () => {
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/accounts/liveAccountIds`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        }
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/accounts/liveAccountIds`
                , init
            )
            const responseData = await response.json();
            return responseData;
        }
        catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    }

    const fetchListofAccountWithLocation = async (productTypes) => {
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/admin/accounts/locations/confupdates`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        }
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/admin/accounts/locations/confupdates`
                , init
            )
            const responseData = await response.json();
            const aarayOfAssetId = [];
            responseData.forEach(item => {
                const arrayOfLocation = item.locations;
                const arrayofAssetId = arrayOfLocation.map((item: { assetId: string }) => item.assetId);
                aarayOfAssetId.push(...arrayofAssetId);
            });
            const arrayOfHealtyDeviceAccount = await fetchHealthDataFloorWise(aarayOfAssetId);
            const arrayOfTotalDeviceAccount = await fetchTOtalDeviceDataFloorWise(aarayOfAssetId);

            const liveaccountData = await fetchliveAccount()

            responseData.forEach(data => {
                const isPresent = liveaccountData.includes(data.id);
                if (isPresent) {
                    data.showInMenu = true
                }
                else {
                    data.showInMenu = false
                }
            });
            let productLevelDeviceHealth = productTypes.reduce((acc, product) => {
                acc[product] = { healthy: 0, total: 0, accountCount: 0 };
                return acc;
            }, {});


            let floorwiseData = []
            let faultyFloors;


            await Promise.all(responseData.map(async (item: {
                locations: [],
                id: number,
                name: string
                accountLevelDeviceHealth?: {
                    ONE: number;
                    TIM: number;
                    'TIM+': number;
                    'BAT-TIM': number;
                    'BAT-TIM+': number;
                    'BAT-FLO': number;
                    PAL: number;
                    PAM: number;
                    FLO: number;
                };
                accountLevelfaultyDevices?: {
                    ONE: number;
                    TIM: number;
                    'TIM+': number;
                    'BAT-TIM': number;
                    'BAT-TIM+': number;
                    'BAT-FLO': number;
                    PAL: number;
                    PAM: number;
                    FLO: number;
                };
                accountLevelTotalDevices?: {
                    ONE: number;
                    TIM: number;
                    'TIM+': number;
                    'BAT-TIM': number;
                    'BAT-TIM+': number;
                    'BAT-FLO': number;
                    PAL: number;
                    PAM: number;
                    FLO: number;
                }
            }) => {
                const arrayOfLocation = item.locations;

                const arrayofAssetId = arrayOfLocation.map((item: { assetId: string }) => item.assetId);

                const arrayOfHealtyDeviceAcount = arrayOfHealtyDeviceAccount.filter(item => arrayofAssetId.includes(item.id));

                const arrayOfTotalDeviceAcount = arrayOfTotalDeviceAccount.filter(item => arrayofAssetId.includes(item.id));

                arrayOfLocation.forEach((location: { assetId: string, TotalFaulty: number, AccountName: string }) => {
                    const matchingTotalHealthy = arrayOfHealtyDeviceAcount.find(healthy => healthy.id === location.assetId);
                    const matchingTotalUnhealthy = arrayOfTotalDeviceAcount.find(unhealthy => unhealthy.id === location.assetId);

                    let HealthyDevices = 0;
                    let TotalDevices = 0;

                    if (matchingTotalHealthy) {
                        Object.keys(matchingTotalHealthy).forEach(key => {
                            if (key !== 'utcEpoch' && key !== 'tzEpoch' && key !== 'processedAt' && key !== 'id') {
                                const count = matchingTotalHealthy[key];
                                if (count !== null && !isNaN(count)) {
                                    HealthyDevices += count;
                                }
                            }
                        });
                    }

                    if (matchingTotalUnhealthy) {
                        Object.keys(matchingTotalUnhealthy).forEach(key => {
                            if (key !== 'utcEpoch' && key !== 'tzEpoch' && key !== 'processedAt' && key !== 'id') {
                                const count = matchingTotalUnhealthy[key];
                                if (count !== null && !isNaN(count)) {
                                    TotalDevices += count;
                                }
                            }
                        });
                    }

                    const unhealthyDevices = TotalDevices - HealthyDevices;
                    const faultyPercentage = TotalDevices > 0 ? (unhealthyDevices / TotalDevices) * 100 : 0;
                    location.TotalFaulty = Math.floor(faultyPercentage);
                    location.AccountName = item.name
                });

                floorwiseData.push(arrayOfLocation)

                let accountLevelDeviceHealth = {
                    Overall: 0,
                    ONE: 0,
                    TIM: 0,
                    "TIM+": 0,
                    "BAT-TIM": 0,
                    "BAT-TIM+": 0,
                    "BAT-FLO": 0,
                    PAL: 0,
                    PAM: 0,
                    FLO: 0,
                }

                let accountLevelFaultyPercentage = {
                    Overall: 0,
                    ONE: 0,
                    TIM: 0,
                    "TIM+": 0,
                    "BAT-TIM": 0,
                    "BAT-TIM+": 0,
                    "BAT-FLO": 0,
                    PAL: 0,
                    PAM: 0,
                    FLO: 0,
                }

                let accountLevelTotalDevices = {
                    Overall: 0,
                    ONE: 0,
                    TIM: 0,
                    "TIM+": 0,
                    "BAT-TIM": 0,
                    "BAT-TIM+": 0,
                    "BAT-FLO": 0,
                    PAL: 0,
                    PAM: 0,
                    FLO: 0,

                };

                // let accountLevelDeviceHealthPercentage = {
                //     Overall: 0,
                //     ONE: 0,
                //     TIM: 0,
                //     "TIM+": 0,
                //     "BAT-TIM": 0,
                //     "BAT-TIM+": 0,
                //     "BAT-FLO": 0,
                //     PAL: 0,
                //     PAM: 0,
                //     FLO: 0,
                // }


                // Total number of device for each floor 
                arrayOfHealtyDeviceAcount.forEach((floorWiseHealthDevice: any) => {
                    Object.keys(floorWiseHealthDevice).forEach(key => {

                        if (accountLevelTotalDevices.hasOwnProperty(key) && floorWiseHealthDevice.hasOwnProperty(key)) {
                            accountLevelDeviceHealth[key] += floorWiseHealthDevice[key];
                        }
                    });
                });


                // Total number of device for each floor 
                arrayOfTotalDeviceAcount.forEach((floorWiseTotalDevice: any) => {
                    Object.keys(floorWiseTotalDevice).forEach(key => {

                        if (accountLevelTotalDevices.hasOwnProperty(key) && floorWiseTotalDevice.hasOwnProperty(key) && floorWiseTotalDevice[key] !== 0) {
                            accountLevelTotalDevices[key] += floorWiseTotalDevice[key];
                        }
                    });
                });

                // Calculate percentage health for each device type
                // Object.keys(accountLevelDeviceHealthPercentage).forEach(key => {
                //     if (key !== 'Overall' && accountLevelTotalDevices[key] > 0) {
                //         accountLevelDeviceHealthPercentage[key] = Math.floor(((accountLevelDeviceHealth[key] / accountLevelTotalDevices[key]) * 100))
                //     }
                //     else {
                //         accountLevelDeviceHealthPercentage[key] = null;
                //     }
                // });

                Object.keys(accountLevelFaultyPercentage).forEach(key => {
                    if (key !== 'Overall' && accountLevelTotalDevices[key] > 0) {
                        accountLevelFaultyPercentage[key] = Math.floor(((accountLevelTotalDevices[key] - accountLevelDeviceHealth[key]) / accountLevelTotalDevices[key]) * 100)
                    }
                    else {
                        accountLevelFaultyPercentage[key] = null;
                    }
                })


                // Calculate Overall percentage health excluding 'ONE'
                let OverallHealthyDevices = Object.entries(accountLevelDeviceHealth).reduce((acc, [key, val]) => {
                    // Exclude 'ONE' product from accumulation
                    if (typeof val === 'number') {
                        return acc + val;
                    }
                    return acc;
                }, 0);

                let OverallTotalDevices = Object.entries(accountLevelTotalDevices).reduce((acc, [key, val]) => {
                    // Exclude 'ONE' product from accumulation
                    if (typeof val === 'number') {
                        return acc + val;
                    }
                    return acc;
                }, 0);


                if (OverallTotalDevices > 0) {
                    // accountLevelDeviceHealthPercentage.Overall = Math.floor(((OverallHealthyDevices / OverallTotalDevices) * 100));
                    accountLevelFaultyPercentage.Overall = Math.floor((((OverallTotalDevices - OverallHealthyDevices) / OverallTotalDevices) * 100));
                } else {
                    // accountLevelDeviceHealthPercentage.Overall = null
                    accountLevelFaultyPercentage.Overall = null
                }


                // Accumulate health and total devices for each product
                Object.keys(productLevelDeviceHealth).forEach(key => {
                    if (accountLevelTotalDevices[key] > 0) {
                        productLevelDeviceHealth[key].accountCount++;
                    }
                    productLevelDeviceHealth[key].healthy += accountLevelDeviceHealth[key];
                    productLevelDeviceHealth[key].total += accountLevelTotalDevices[key];
                });

                item.accountLevelTotalDevices = accountLevelTotalDevices;
                // item.accountLevelDeviceHealth = accountLevelDeviceHealthPercentage;
                item.accountLevelfaultyDevices = accountLevelFaultyPercentage;
            }));

            const flatFloorwise = floorwiseData.flat()
            const sortedArray = flatFloorwise.sort((a, b) => b.TotalFaulty - a.TotalFaulty);
            faultyFloors = sortedArray.slice(0, 4);


            return {
                productLevelDeviceHealth,
                faultyFloors,
                responseData
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    }

    if (loading) {
        return (
            <PageLoader
                loading={true}
            />
            // <div style={{ height: '100vh', width: '100%', textAlign: 'center' }}>
            //     Loading...
            // </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <HealthOverviewContext.Provider value={{
            accountsInfo: accountsInfo,
            quickInfoData: quickInfoData,
            insightTextData: faultyFloorsDetails,
            // setIsRiskView,
        }}>
            {children}
        </HealthOverviewContext.Provider >
    );
};

// Skip prop validation by setting propTypes to null
HealthOverviewProvider.propTypes = null;

export default HealthOverviewProvider;
